import { Guid } from "guid-typescript";
export default class UserPasswordResetRequestModel {
  public emailAddress: string = "";

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
