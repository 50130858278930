























































import { Vue, Component } from "vue-property-decorator";
import apiService from "../services/apiService";
import UserPasswordResetRequestModel from "@/models/UserPasswordResetRequestModel";
@Component
export default class ForgottenPassword extends Vue {
  private valid: boolean = false;
  private dialog: boolean = false;
  private snackbar: boolean = false;
  private alertSnackbar: boolean = false;

  private vertical: boolean = true;
  private y: string = "top";
  private x: string = "right";

  successMesssage = "Password reset email has been successfully sent";
  errorMessage =
    "Email address does not exists. Please enter a valid email address";

  private model: UserPasswordResetRequestModel = new UserPasswordResetRequestModel();

  private cancel() {
    this.$router.push({ name: "login" });
  }

  private async sendPasswordResetRequest() {
    try {
      await apiService.sendPasswordReset(this.model);
      this.snackbar = true;
      this.model.emailAddress = "";
      this.$router.push({ name: "login" });
    } catch (error) {
      this.alertSnackbar = true;
      this.model.emailAddress = "";
    }
  }
}
